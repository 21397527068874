import { stringify } from 'qs'
import { environment } from '../environment'
import { Options } from './types'
import { MarketingApi } from './MarketingApi'

/* global document window navigator */
interface GlobalEvent {
  callback: (event: any) => any
  event: string
}

const URLRegex =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/

const redirectIfValidUrl = (url: string) => {
  if (!URLRegex.test(url)) {
    console.error('Invalid redirect URL')
    return
  }

  window.location.href = url
}

export class PopUp {
  form: MarketingApi
  options: Options
  data: Record<string, any>
  domElement?: HTMLElement
  crmEvent?: any
  redirect: any = {}
  layout?: HTMLDivElement
  iframe?: HTMLIFrameElement
  globalEvents: GlobalEvent[] = []

  constructor(
    formType: MarketingApi,
    data: Record<string, any>,
    domElement?: HTMLElement,
    crmEvent?: any
  ) {
    this.form = formType
    this.options = formType.options
    this.data = data
    this.domElement = domElement
    this.crmEvent = crmEvent
    this.redirect = {}

    //@ts-ignore TODO: add explanation
    if (window.addEventListener) {
      this.addAndRegisterListener('message', this.onMessage)
      //@ts-ignore TODO: add explanation
    } else if (window.attachEvent) {
      //@ts-ignore TODO: add explanation
      window.attachEvent('onmessage', this.onMessage, false)
    }
    this.buildLayout()
  }

  setData = (formData: Record<string, any>, domElement?: HTMLElement) => {
    this.data = formData
    this.domElement = domElement
    this.redirect = {}
  }

  onMessage = (event: MessageEvent) => {
    if (event.data?.action === 'close') {
      this.close()
    } else if (event.data.action === 'REDIRECT') {
      redirectIfValidUrl(event.data.url)
    } else if (event.data.action === 'booking-confirmed') {
      this.form.onSuccess(event.data.args)
    } else if (event.data.action === 'error') {
      this.form.onError(event.data.args)
    } else if (event.data.action === 'prospect-routed') {
      this.options.onRouted?.(event.data.args)
    }
  }

  removeLayout() {
    if (this.layout) {
      this.getParentNode().removeChild(this.layout)
      this.layout = undefined
      this.data = {}
      this.buildLayout()
    }
  }

  close() {
    this.removeLayout()
    this.form.onClose(false)
  }

  getParentNode() {
    if (!this.domElement) {
      return document.body
    }
    if (typeof this.domElement === 'string') {
      const element = document.querySelector(this.domElement)
      if (element) {
        return element
      } else {
        return document.body
      }
    } else {
      return this.domElement
    }
    return document.body
  }

  getPopUpData() {
    const { trigger = 'ThirdPartyForm' } = this.options
    const closeOnOutside = this.options.closeOnOutside

    delete this.data['']
    delete this.data['hs_context']
    const parsedData = {
      ...this.data,
      closeOnOutside,
      by: this.options.by ? this.options.by : undefined,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }
    return { ...parsedData, trigger, sourceUrl: window.location.href }
  }

  getPopUpBaseUrl() {
    const { domain: tenant, router: conciergeSlug } = this.options
    const baseUrl =
      process.env.NODE_ENV === 'development'
        ? `/booking-app/${tenant}`
        : `https://${tenant}.${environment.domain}`

    return `${baseUrl}/concierge-router/${conciergeSlug}`
  }

  getPopUpUrl() {
    const parsedData = this.getPopUpData()

    const queryString = stringify({ ...parsedData })
    const link = `${this.getPopUpBaseUrl()}?${queryString}`

    // eslint-disable-next-line no-console
    console.log({
      data: parsedData,
      link,
    })

    return link
  }

  buildLayout() {
    if (!this.layout) {
      const div = document.createElement('div')
      div.style.top = '0'
      div.style.left = '0'
      div.style.right = '0'
      div.style.bottom = '0'
      div.style.position = 'fixed'
      div.style.zIndex = '-1'
      this.getParentNode().appendChild(div)
      this.layout = div
    }

    // we are building the iframe
    // in order to preload it
    const iframeElement = document.createElement('iframe')
    iframeElement.className = 'chilipiper-frame'
    iframeElement.src = this.getPopUpUrl()
    iframeElement.style.border = '0'
    iframeElement.style.overflow = 'hidden'
    iframeElement.style.height = '0'
    iframeElement.style.width = '1px'
    iframeElement.style.minWidth = '0'

    this.layout.appendChild(iframeElement)
    this.iframe = iframeElement
  }

  showPopUp(shouldPostMessage?: boolean) {
    const div = this.layout
    const iframe = this.iframe

    if (div && iframe) {
      div.style.zIndex = '99999'
      // as we already prefetched the url and created the iframe
      // this will just update the url and the iframe dimensions
      const handleFocusIframe = () => {
        iframe.style.height = '100%'
        iframe.style.minWidth = '100%'
        return iframe.focus()
      }
      handleFocusIframe()
      // if the data hasn't changed, we don't need to update data on popup
      // the url has already been loaded, just need to change width and height
      if (shouldPostMessage) {
        iframe.contentWindow?.postMessage(
          { type: 'UPDATE_CONCIERGE_FORM_DATA', data: stringify({ ...this.getPopUpData() }) },
          '*'
        )
      }
    }
  }

  addAndRegisterListener = (event: string, callback: (event: any) => any) => {
    this.globalEvents.push({ event, callback })
    window.addEventListener(event, callback)
  }
}
