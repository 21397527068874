import { MarketingApi } from '../MarketingApi'
import { submitAndRoute } from '../concierge'
import { Optional, Options } from '../types'

export const deployMarketo = (options: Optional<Options, 'form'>) => {
  if (window.MktoForms2) {
    const marketingApi = new MarketingApi(options)
    window.MktoForms2.whenReady((form: any) => {
      form.onSuccess((values: any) => {
        const submittedFormId = form.getId().toString() as string
        const hasFormIdOption = !!options.formId || (options.formIds ?? []).length > 0
        if (
          !hasFormIdOption ||
          submittedFormId === options.formId ||
          (options.formIds ?? []).includes(submittedFormId)
        ) {
          submitAndRoute(
            {
              ...options,
              map: true,
              lead: values,
            },
            marketingApi
          )
          return false
        }
        // eslint-disable-next-line no-console
        console.log(`Submitted form ID ${submittedFormId} is not in the options`)
        return true
      })
    })
  }
}
