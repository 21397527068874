import { MarketingApi } from '../MarketingApi'
import { submitAndRoute } from '../concierge'
import { Optional, Options } from '../types'

export const deployPardotFormHandlerOnThankYouPage = (options: Optional<Options, 'form'>) => {
  const leadValues: Record<string, string> = {}
  const uri = decodeURIComponent(document.location.search.replace(/\+/g, ' '))
  const urlParams = new URLSearchParams(uri)
  const entries = urlParams.entries()
  let valid = false
  for (const [key, value] of entries) {
    if (key.toLowerCase().includes('email') && value.includes('@')) {
      leadValues[key] = value.replaceAll(' ', '+') // fix spaces for emails
      valid = true
    } else {
      leadValues[key] = value
    }
  }
  if (valid) {
    const marketingApi = new MarketingApi({
      ...options,
      domain: leadValues['CPTenantDomain'],
      router: leadValues['CPTenantRouter'],
      lead: leadValues,
      map: true,
    })
    submitAndRoute({ ...marketingApi.options }, marketingApi)
    // eslint-disable-next-line no-console
    console.log(leadValues)
  }
}

export const deployPardotOnLookAndFeel = (options: Optional<Options, 'form'>) => {
  const formId = options.formId ?? '#pardot-form'
  const log = (msg: any) => {
    if (typeof options.debug !== 'undefined' ? options.debug : true) {
      // eslint-disable-next-line no-console
      console.log(msg)
    }
  }
  // Submit button click handler
  function submitHandler(_event: any) {
    if (form) {
      // Add URL parameters to form action
      const params = new URLSearchParams(
        getLeadObject(options.domain, options.router, form)
      ).toString()
      form.action = `${form.action}?${params}`
      // eslint-disable-next-line no-console
      console.log(form.action)
    }
  }
  // Get form
  let form = document.querySelector(formId) as HTMLFormElement
  // Retry adding the form event listener
  addFormListener()
  function addFormListener(count = 1) {
    if (form) {
      form.addEventListener('submit', submitHandler)
      return
    } else if (
      (typeof options.retryFormSearch !== 'undefined' ? options.retryFormSearch : true) &&
      count < 10
    ) {
      setTimeout(() => {
        form = document.querySelector(formId) as HTMLFormElement
        addFormListener(count + 1)
      }, 1000)
    } else {
      // eslint-disable-next-line no-console
      console.log('no form found on this page, id used - ' + formId)
      return
    }
  }
  // Get Lead object from form fields
  // eslint-disable-next-line complexity
  function getLeadObject(tenantDomain: string, tenantRouter: string, form: HTMLFormElement) {
    // Loop through all form elements and map
    const data = {
      CPTenantDomain: tenantDomain,
      CPTenantRouter: tenantRouter,
    } as Record<string, string>
    const labelsDict = {} as Record<string, string>
    // Fill lead object
    for (let i = 0, elem; (elem = form.elements[i++] as HTMLInputElement); ) {
      if (elem.type.includes('submit') || elem.type.includes('fieldset')) {
        continue
      }
      if (elem.type.includes('select')) {
        const selectElem = elem as unknown as HTMLSelectElement
        // Save to lead obj
        // eslint-disable-next-line max-depth
        if (selectElem.selectedIndex === 0) {
          data[elem.name] = '[not provided]'
        } else {
          data[elem.name] = selectElem.options[selectElem.selectedIndex].text
        }
      } else if (elem.type === 'hidden' || elem.parentElement?.className.includes('hidden')) {
        // Save to lead obj
        data[elem.name] = elem.value ? elem.value : '[not provided]'
      } else {
        // Get the field name from label or element placeholder
        const field_name = elem.type === 'radio' ? elem.name.replace('[]', '') : elem.name
        // Save to lead obj
        // eslint-disable-next-line max-depth
        if (elem.type.includes('radio')) {
          // eslint-disable-next-line max-depth
          if (elem.checked === true) {
            data[field_name] = labelsDict[elem.id]
          }
        } else if (elem.type.includes('checkbox')) {
          data[field_name] = String(elem.checked) || '[not provided]'
        } else {
          data[field_name] = elem.value || '[not provided]'
        }
      }
    }
    // Log data and labels for debugging
    log(labelsDict)
    log(data)
    return data
  }
}

export const deployPardotIframeOnThankYouPage = () => {
  window.parent.postMessage(
    {
      message: 'PARDOT_FORM_SUCCESS',
      data: window.location.search,
    },
    '*'
  )
}

export const deployPardotIframeOnParentPage = (options: Optional<Options, 'form'>) => {
  const marketingApi = new MarketingApi(options)
  // Below is the event listener that will listen for the Pardot Events
  window.addEventListener('message', receiveMessage, false)
  function receiveMessage(event: any) {
    // Form was submitted and validated, call ChiliPiper
    if (event.data && event.data.message === 'PARDOT_FORM_SUCCESS') {
      const leadValues = {} as Record<string, string>
      const uri = decodeURIComponent(event.data.data.replace(/\+/g, ' '))
      const urlParams = new URLSearchParams(uri)
      const entries = urlParams.entries()
      for (const [key, value] of entries) {
        leadValues[key] = value
      }
      submitAndRoute(
        {
          domain: leadValues['CPTenantDomain'],
          router: leadValues['CPTenantRouter'],
          lead: leadValues,
        },
        marketingApi
      )
    }
  }
}
